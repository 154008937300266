import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const useAuth = () => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState(null);
    const [graphToken, setGraphToken] = useState(null);
    const [expiration, setExpiration] = useState(null);
    const [graphExpiration, setGraphExpiration] = useState(null);

    useEffect(() => {
        const authenticateAndCheckRoles = async () => {
            if (!isAuthenticated && inProgress === 'none') {
                await instance.loginRedirect();
            }

            if (isAuthenticated) {
                try {
                    const account = accounts[0];

                    // Get access token
                    const response = await instance.acquireTokenSilent({
                        scopes: ["650ce438-b6ac-40c9-8226-5c8f45213dcf/.default"],
                        account: account
                    });
                    setToken(response.accessToken);
                    setExpiration(response.expiresOn);

                    // Get graph token
                    const graphResponse = await instance.acquireTokenSilent({
                        scopes: [
                            "https://graph.microsoft.com/User.Read.All",
                            "https://graph.microsoft.com/Mail.Read",
                            "https://graph.microsoft.com/Calendars.Read"
                        ],
                        account: account
                    });
                    setGraphToken(graphResponse.accessToken);
                    setGraphExpiration(graphResponse.expiresOn);

                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.loginRedirect();
                    }
                }
            }
        };

        authenticateAndCheckRoles();
    }, [isAuthenticated, inProgress, instance, accounts]);

    useEffect(() => {
        const refreshTokens = async () => {
            if (expiration && Date.now() >= expiration - 65000) { // Refresh 65 seconds before expiration
                console.log('Main token expired, refreshing.');
                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: ["650ce438-b6ac-40c9-8226-5c8f45213dcf/.default"],
                        account: accounts[0]
                    });
                    setToken(response.accessToken);
                    setExpiration(response.expiresOn);
                    console.log('Main token refreshed.');
                } catch (error) {
                    console.warn('Main token refresh failed, triggering login.');
                    instance.loginRedirect();
                }
            }

            if (graphExpiration && Date.now() >= graphExpiration - 65000) { // Refresh Graph token before expiration
                console.log('Graph token expired, refreshing.');
                try {
                    const graphResponse = await instance.acquireTokenSilent({
                        scopes: [
                            "https://graph.microsoft.com/User.Read.All",
                            "https://graph.microsoft.com/Mail.Read",
                            "https://graph.microsoft.com/Calendars.Read"
                        ],
                        account: accounts[0]
                    });
                    setGraphToken(graphResponse.accessToken);
                    setGraphExpiration(graphResponse.expiresOn);
                    console.log('Graph token refreshed.');
                } catch (error) {
                    console.warn('Graph token refresh failed, triggering login.');
                    instance.loginRedirect();
                }
            }
        };

        const interval = setInterval(refreshTokens, 60000); // Check every 60 seconds
        return () => clearInterval(interval);
    }, [expiration, graphExpiration, instance, accounts]);

    return { isAuthenticated, user: accounts[0], token, graphToken };
};

export default useAuth;
