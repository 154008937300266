// frontend/src/components/MessageContent.tsx
import React, { ReactNode, useRef, useState, useEffect, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';
import * as XLSX from 'xlsx';
import { FaFileExcel, FaCopy } from 'react-icons/fa';
import { Content } from '../types';
import { useFileIcon } from '../utils/useFileIcon'; // Import the useFileIcon hook
import MermaidContainer from './MermaidContainer';
import { v4 as uuidv4 } from 'uuid';

// Define the isMounted hook to track the mounted state
const useIsMounted = () => {
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

interface MessageContentProps {
  c: Content;
  role: string;
  openImageModal: (imageUrl: string) => void;
  id: string;
}

interface TableCellProps {
  children: ReactNode;
}

const MarkdownTable: React.FC<{ children: ReactNode }> = ({ children }) => {
  const tableRef = useRef<HTMLTableElement>(null);

  const exportTableToExcel = () => {
    if (tableRef.current) {
      const rows = Array.from(tableRef.current.querySelectorAll('tr')).map(row =>
        Array.from(row.querySelectorAll('th, td')).map(cell => cell.textContent || '')
      );
      const worksheet = XLSX.utils.aoa_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'exported_table.xlsx');
    }
  };


  return (
    <div style={{backgroundColor: '#fff', color: '#555', fontFamily: 'Söhne, ui-sans-serif, system-ui, "Segoe UI"', fontSize: '1.0em'}} data-skip-mathjax='true'>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px', color: '#555', minHeight:'21px' }}>
        <button
          onClick={exportTableToExcel}
          style={{ background: 'none', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center', color: '#555', fontSize: '1.0em' }}
        >
          <FaFileExcel size={16} style={{ marginRight: '8px', color: 'green' }} />
          Export to Excel
        </button>
      </div>
      <div style={{ display: 'flex', fontFamily: 'Söhne, ui-sans-serif, system-ui, "Segoe UI"', fontSize: '1.0em'}} data-skip-mathjax='true'>
        <table ref={tableRef} className="border-collapse border border-black px-3 py-1 dark:border-white" data-skip-mathjax='true'>
          {children}
        </table>
      </div>
    </div>
  );
};

  const MessageContent: React.FC<MessageContentProps> = memo(({ c, role, openImageModal, id }) => {
    const [imageGrids, setImageGrids] = useState<{ url: string, alt: string }[][]>([]);
    const [regularContent, setRegularContent] = useState<string>('');
    const contentRef = useRef<HTMLDivElement>(null);
    const isMounted = useIsMounted();
    let mermaidChartCount = 0;

    // Function to process the markdown text
    const processMarkdown = (text: string): { grids: { url: string, alt: string }[][]; regularContent: string } => {
      const lines: string[] = text.split('\n');
      const grids: { url: string, alt: string }[][] = [];
      let regularContent: string[] = [];
      let currentGrid: { url: string, alt: string }[] = [];
      let inGrid = false;
    
      lines.forEach((line: string) => {
        if (line.includes('<!-- Start of Image Grid -->')) {
          inGrid = true;
          currentGrid = [];
        } else if (line.includes('<!-- End of Image Grid -->')) {
          inGrid = false;
          if (currentGrid.length > 0) {
            grids.push(currentGrid);
          }
        } else if (inGrid && line.startsWith('![')) {
          const altMatch = /\[([^\]]*)\]/.exec(line); // Extract the alt text
          const imageUrlMatch = /\((.*?)\)/.exec(line); // Extract the image URL
          if (imageUrlMatch) {
            currentGrid.push({
              url: imageUrlMatch[1],
              alt: altMatch ? altMatch[1] : '', // Use extracted alt text, or empty if not found
            });
          }
        } else if (!inGrid) {
          regularContent.push(line);
        }
      });
    
      return { grids, regularContent: regularContent.join('\n') };
    };

    // Apply processing whenever the markdown content changes
    useEffect(() => {
      if (isMounted.current && c && 'text' in c && typeof c.text === 'string' && role === 'assistant') {
        // console.log('message content changed');
        const { grids, regularContent } = processMarkdown(c.text);
        setImageGrids(grids);
        setRegularContent(regularContent);
      }
    }, [c, role, isMounted]);

    const copyToClipboard = (text?: string) => {
      if (text) {
        navigator.clipboard.writeText(text).then(() => {
          console.log('Copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      } else if (contentRef.current) {
        const fullText = contentRef.current.innerText;
        navigator.clipboard.writeText(fullText).then(() => {
          console.log('Copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      }
    };

  const mathjaxOptions = {
    tex: {
      inlineMath: [['$', '$'], ['\\(', '\\)']],
      displayMath: [['$$', '$$'], ['\\[', '\\]']],
    },
    options: {
      options: {
        skipHtmlTags: { '[-]': ['pre', 'code', 'table', 'thead', 'tbody', 'tr', 'td', 'th'] },
      },
    },
  };

  const containsTable = (text: string): boolean => {
    const tableRegex = /\|.*\|/; // Matches a table row
    const headerRegex = /-+\s*\|/; // Matches a table header
    return tableRegex.test(text) || headerRegex.test(text);
  };
  
  const convertInlineMath = (text: string) => {
    return text.replace(/<AI_STATUS>(.*?)<\/AI_STATUS>/g, '');
  };

  const IconComponent = useFileIcon(c.type === 'file' ? c.file.name : '');

  if (c.type === 'text') {
    if (role === 'assistant') {
      try {
        const processedText = convertInlineMath(regularContent);
        
        return (
          <div ref={contentRef}>
            <ReactMarkdown
              key={`rm-${id}`}
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeMathjax, mathjaxOptions]}
              components={{
                img: ({ src, alt }: { src: string, alt: string }) => (
                  <img
                    src={src}
                    alt={alt}
                    style={{ cursor: 'pointer' }}
                    onClick={() => openImageModal(src!)}
                  />
                ),
                code({ node, inline, className, children, ...props }: { node: any, inline: boolean, className: string, children: ReactNode }) {
                  const match = /language-(\w+)/.exec(className || '');
                  // console.log(children?.toString());
                  if (match && match[1] === 'markdown') {
                    // Re-parse as Markdown when the language is 'markdown'
                    if(containsTable(processedText)) {
                      return (
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            table({ children }: TableCellProps) {
                              return <MarkdownTable data-skip-mathjax='true'>{children}</MarkdownTable>;
                            },
                            th({ children }: TableCellProps) {
                              return (
                                <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white" data-skip-mathjax='true'>
                                  {children}
                                </th>
                              );
                            },
                            td({ children }: TableCellProps) {
                              return (
                                <td className="break-words border border-black px-3 py-1 dark:border-white" data-skip-mathjax='true'>
                                  {children}
                                </td>
                              );
                            },
                          }}
                        >
                          {String(children).replace(/\n$/, '')}
                        </ReactMarkdown>
                      );
                    }
                    return (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm, remarkMath]}
                        rehypePlugins={[rehypeMathjax, mathjaxOptions]}
                        components={{
                          table({ children }: TableCellProps) {
                            return <MarkdownTable data-skip-mathjax='true'>{children}</MarkdownTable>;
                          },
                          th({ children }: TableCellProps) {
                            return (
                              <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white" data-skip-mathjax='true'>
                                {children}
                              </th>
                            );
                          },
                          td({ children }: TableCellProps) {
                            return (
                              <td className="break-words border border-black px-3 py-1 dark:border-white" data-skip-mathjax='true'>
                                {children}
                              </td>
                            );
                          },
                        }}
                      >
                        {String(children).replace(/\n$/, '')}
                      </ReactMarkdown>
                    );
                  }

                  // Check if the code block is a Mermaid block
                  if (match && match[1] === 'mermaid') {
                    mermaidChartCount += 1;
                    const mermaidId = `mermaid-${id}-${mermaidChartCount}`;
                    const val = children?.toString() || "";
                    
                    return (
                      <MermaidContainer key={mermaidId} id={mermaidId} content={val} />
                    );
                  }

                  return !inline && match ? (
                    <div className="code-container">
                      <div className="code-header">
                        <span>{match[1]}</span>
                        <button
                          className="copy-button"
                          onClick={() => copyToClipboard(String(children))}
                          style={{ background: 'none', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        >
                          <FaCopy size={12} style={{ marginRight: '8px' }} />
                          Copy code
                        </button>
                      </div>
                      <SyntaxHighlighter
                        style={atomDark}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    </div>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
                table({ children }: TableCellProps) {
                  return <MarkdownTable>{children}</MarkdownTable>;
                },
                th({ children }: TableCellProps) {
                  return (
                    <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white">
                      {children}
                    </th>
                  );
                },
                td({ children }: TableCellProps) {
                  return (
                    <td className="break-words border border-black px-3 py-1 dark:border-white">
                      {children}
                    </td>
                  );
                },
              }}
            >
              {processedText}
            </ReactMarkdown>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
              {/* Copy to Clipboard Button */}
              <button
                onClick={() => copyToClipboard()} // Call the function with no arguments
                style={{ background: 'none', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center', marginRight: '10px' }}
                title="Copy to Clipboard"
              >
                <FaCopy size={16} style={{ marginRight: '8px', color: 'gray' }} />
              </button>
            </div>
            {imageGrids.map((grid, index) => (
              <div key={index} className="image-grid">
                {grid.map(({ url, alt }, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={url}
                    alt={alt || 'Image'} // Use actual alt or default if missing
                    style={{ cursor: 'pointer', margin: '5px' }}
                    onClick={() => openImageModal(url)}
                  />
                ))}
              </div>
            ))}
          </div>
        );
      } catch (error) {
        console.error("Error rendering markdown:", error);
        return <p>{c.text}</p>;
      }
    } else {
      return <pre className='user-message-pre not-prose'>{c.text}</pre>;
    }
  } else if (c.type === 'image_url') {
    return <img key={c.type} src={c.image_url.url} alt="User input" style={{ maxWidth: '384px', maxHeight: '384px', objectFit: 'scale-down' }} />;
  } else if (c.type === 'file' && IconComponent) {
    return (
      <div className="file-message" style={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent size={36} style={{ marginRight: '8px' }} />
        <a key={c.type} href={c.file.url} target="_blank" rel="noopener noreferrer">
          {c.file.name}
        </a>
      </div>
    );
  }
  return null;
}, (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.role === nextProps.role &&
    prevProps.c === nextProps.c
  );
});

export default MessageContent;
