import { useCallback, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addStreamedMessage, addMessage, renameConversation, updateStreamingText, clearStreamingText, setIsSending, setFinishReason } from '../chatReducer';
import useAuth from './useAuth';
import { Content, TextContent } from '../types';

const webSocketPool: { [key: string]: WebSocket } = {}; 

const useWebSocket = (userId: string, conversationId: string) => {
  const dispatch = useDispatch();
  const { token, graphToken, isAuthenticated } = useAuth();
  const lastTokenRef = useRef<string | null>(null);
  const lastGraphTokenRef = useRef<string | null>(null);
  const [isReconnecting, setIsReconnecting] = useState(false);

  const initializeWebSocket = useCallback((): WebSocket | null => {
    if (!token || !graphToken) return null;

    const ws = new WebSocket(
      `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}` + `${window.location.host}/ws/chat`,
      ["default", token, graphToken]
    );

    ws.onopen = () => {
      console.log(`WebSocket connected for conversation ${conversationId}`);
      setIsReconnecting(false);
    };

    ws.onclose = (event) => {
      console.log(`WebSocket closed for conversation ${conversationId}`, event.code);
      delete webSocketPool[conversationId];

      if (event.code === 1008 || event.code === 4001) {
        console.warn("WebSocket closed due to authentication. Will refresh token on next send.");
        setIsReconnecting(true);
      }
    };

    ws.onerror = (error) => {
      console.error(`WebSocket error for conversation ${conversationId}`, error);
      ws.close();
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.conversationId === conversationId) {
        dispatch(updateStreamingText(message));
      }

      if (message.finish_reason) {
        dispatch(setFinishReason({ conversationId, finishReason: message.finish_reason }));
        dispatch(setIsSending({ conversationId, isSending: false }));

        if (message.finish_reason === 'cancelled') {
          dispatch(clearStreamingText(conversationId));
          dispatch(addMessage({ conversationId, message: { role: 'assistant', content: [{ type: "text", text: "Your request has been cancelled. Let me know what else I can help you with." }], status: null } }));
        }

        if (message.finish_reason === 'stop' || message.finish_reason === 'error') {
          dispatch(addStreamedMessage({ conversationId }));
          dispatch(clearStreamingText(conversationId));
        }

        ws.close();
      }
    };

    webSocketPool[conversationId] = ws;
    lastTokenRef.current = token;
    lastGraphTokenRef.current = graphToken;
    return ws;
  }, [conversationId, dispatch, token, graphToken]);

  const sendMessage = async (
    newContent: Content[],
    messages: any[],
    addToMessages = true,
    messageTextOverride: string | null = null
  ) => {
    if (!newContent.length) return;

    dispatch(clearStreamingText(conversationId));
    dispatch(setIsSending({ conversationId, isSending: true }));

    if (addToMessages) {
      let overrideContent: Content[] | null = null;

      if (messageTextOverride) {
        overrideContent = [{ type: "text", text: messageTextOverride } as TextContent];
      }

      dispatch(addMessage({ conversationId, message: { role: 'user', content: overrideContent ? overrideContent : newContent, status: null } }));
    }

    if (token !== lastTokenRef.current || graphToken !== lastGraphTokenRef.current) {
      console.log("Token changed, resetting WebSocket...");
      if (webSocketPool[conversationId]) {
        webSocketPool[conversationId].close();
      }
      initializeWebSocket();
    }

    const payload = {
      userId,
      conversationId,
      messages: [
        ...messages.map((msg: any) => ({
          role: msg.role,
          content: msg.content,
        })),
        {
          role: 'user',
          content: newContent,
        },
      ],
    };

    let ws: WebSocket | null = webSocketPool[conversationId] || initializeWebSocket();
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(payload));
    } else if (ws) {
      ws.onopen = () => {
        ws!.send(JSON.stringify(payload));
      };
    } else {
      console.warn("WebSocket is not open. Message was not sent.");
    }

    // Set chat name
    const conversation = { title: "New chat" };
    if (conversation.title === "New chat" || conversation.title === "New Conversation") {
      const response = await fetch('/api/generate-title', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
  
      if (response.ok) {
        let newTitle = await response.text();
        newTitle = newTitle.replace(/^"|"$/g, '');
        dispatch(renameConversation({ conversationId, newTitle }));
      } else {
        console.error('Failed to generate title');
      }
    }
  };

  const handleContinue = (
    streamingText: string,
    messages: any[],
  ) => {
    const payload = {
      conversationId,
      messages: [
        ...messages.map((msg: any) => ({
          role: msg.role,
          content: msg.content
        })),
        { role: 'assistant', content: [{ type: 'text', text: streamingText }] },
        { role: 'user', content: [{ type: 'text', text: 'Continue. Please continue from right after where you left off. DO NOT repeat the last line, just start with the next character. DO NOT add any text before continuation that will break formatting.' }] }
      ]
    };

    dispatch(clearStreamingText(conversationId));

    let ws: WebSocket | null = webSocketPool[conversationId] || initializeWebSocket();
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(payload));
    } else if (ws) {
      ws.onopen = () => {
        ws!.send(JSON.stringify(payload));
      };
    } else {
      console.warn("WebSocket could not be opened. Continue message was not sent.");
    }
  };

  return {
    sendMessage,
    handleContinue,
    isReconnecting
  };
};

export default useWebSocket;
